import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/detail',
    name: 'detail',

    component: () => import('../components/detail.vue'),
    meta: { keepAlive: true }
  },
  {
    path: '/selectOppositerFile',
    name: 'selectOppositerFile',
    component: () => import('../components/selectOppositerFile/selectOppositerFile.vue')
  },
  {
    path: '/addOppositeFile',
    name: 'addOppositeFile',
    component: () => import('../components/addOppositeFile/addOppositeFile.vue')
  }, {
    path: '/userManagement',
    name: 'userManagement',
    component: () => import('../components/userManagement/userManagement.vue')
  }, {
    path: '/relatedContract',
    name: 'relatedContract',
    component: () => import('../components/relatedContract/relatedContract.vue')
  }, {
    path: '/uploadContract',
    name: 'uploadContract',
    component: () => import('../components/uploadContract/uploadContract.vue')
  }, {
    path: '/contractApprovalTemplate',
    name: 'contractApprovalTemplate',
    component: () => import('../components/contractApprovalTemplate/contractApprovalTemplate.vue')
  }, {
    path: '/pdfFile',
    name: 'pdfFile',
    component: () => import('../components/pdfFile/pdfFile.vue')
  }, {
    path: '/pigeonholeFile',
    name: 'pigeonholeFile',
    component: () => import('../components/pigeonholeFile/pigeonholeFile.vue')
  }, {
    path: '/addTableItem',
    name: 'addTableItem',
    component: () => import('../components/addTableItem/addTableItem.vue')
  }, {
    path: '/toSign',
    name: 'toSign',
    component: () => import('../components/toSign/toSign.vue')

  }
]

const router = new VueRouter({
  routes
})

export default router
