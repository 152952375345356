import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 在主入口main.js文件中引入
import '../src/assets/style/basic.css'
import '../src/assets/style/public.css'
import 'vant/lib/index.css'
import 'amfe-flexible/index.js'
import 'lib-flexible/flexible.js'
import '@/assets/style/index.scss'
import 'video.js/dist/video-js.css'
import '@/assets/iconfont/iconfont.css'
import common from '@/utils/common'
import Vant from 'vant'
import request from '@/utils/request'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Vuex from 'vuex'

Vue.use(ElementUI)
// import VideoPlayer from 'vue-video-player'
// require('video.js/dist/video-js.css')
// require('vue-video-player/index')
// Vue.use(VideoPlayer)

Vue.config.productionTip = false
Vue.prototype.$noMultipleClicks = common.noMultipleClicks
Vue.use(Vant)
Vue.use(Vuex)
// Vue.prototype.$video = Video
Vue.prototype.$EventBus = new Vue()
Vue.prototype.request = request
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
