import axios from 'axios'
import Element from 'element-ui'
// import {serverIp} from "../../public/config";
// http://192.168.1.7:8088/railwayContract  https://ht.hnjsxk.com/railwayContract/railwayContract`
const request = axios.create({
  baseURL: 'https://ht.hnjsxk.com/railwayContract/railwayContract', // 注意！！ 这里是全局统一加上了 '/api' 前缀，也就是说所有接口都会加上'/api'前缀在，页面里面写接口的时候就不要加 '/api'了，否则会出现2个'/api'，类似 '/api/api/user'这样的报错，切记！！！
  timeout: 5000
})

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(config => {
  config.headers['Content-Type'] = 'application/json;charset=utf-8'
  // 获取用户信息
  const token = localStorage.getItem('token') ? localStorage.getItem('token') : null
  if (token) {
    config.headers['X-Access-Token'] = token // 设置请求头
  }
  return config
}, error => {
  return Promise.reject(error)
})

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
  response => {
    let res = response.data
    // 如果是文件返回
    if (response.config.responseType === 'blob') {
      return res
    }
    // 兼容服务端返回的字符串数据
    if (typeof res === 'string') {
      res = res ? JSON.parse(res) : res
    }
    // 当验证权限不足的时候给出提示
    if (res.code == 401) {
      Element.Message({
        message: res.msg,
        type: 'error'
      })
    }
    return res
  },
  error => {
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default request
